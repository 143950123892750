import Enum from './enum'

/**
 * 打合せ方法
 */
class MeetingBy extends Enum {
    static VISITING = 1; //訪問
    static ZOOM = 2; //Zoom
    static TEAMS = 5; //Teams
    static COMING = 3; //来社
    static TEL = 4; //電話
    static ONLINE = 6; //その他Web会議

    static values() {
        return {
            [this.VISITING]: '訪問',
            [this.ZOOM]: 'Zoom',
            [this.TEAMS]: 'Teams',
            [this.COMING]: '来社',
            [this.TEL]: '電話',
            [this.ONLINE]: 'その他Web会議',
        }
    }

    static isWebMeeting(value) {
        return (
            value === this.ZOOM
            || value === this.TEAMS
            || value === this.ONLINE
        );
    }
}

export default MeetingBy;
